import { ChainId, JSBI, Percent, Token, WDEV } from "bizboa-swap-sdk";
import { AbstractConnector } from "@web3-react/abstract-connector";
import { CONTRACT_ADDRESS_NETWORKS } from "../boaswap_address";

import { injected } from "../connectors";
import { BridgeDirection } from "../state/transactions/actions";
//fortmatic, portis, walletconnect, walletlink, lattice
export const ROUTER_ADDRESS: { [key: string]: string } = {
  [ChainId.HARDHAT]: CONTRACT_ADDRESS_NETWORKS[ChainId.HARDHAT].routerv2,
  [ChainId.STANDALONE]: CONTRACT_ADDRESS_NETWORKS[ChainId.STANDALONE].routerv2,
  [ChainId.SEPOLIA]: CONTRACT_ADDRESS_NETWORKS[ChainId.SEPOLIA].routerv2,
  [ChainId.BIZTESTNET]: CONTRACT_ADDRESS_NETWORKS[ChainId.BIZTESTNET].routerv2,
  [ChainId.BIZNET]: CONTRACT_ADDRESS_NETWORKS[ChainId.BIZNET].routerv2
};

export const OPPOSITE_CHAIN: { [key: string]: number } = {
  [ChainId.HARDHAT]: ChainId.STANDALONE,
  [ChainId.STANDALONE]: ChainId.STANDALONE,
  [ChainId.MAINNET]: ChainId.BIZNET,
  [ChainId.BIZNET]: ChainId.MAINNET,
  [ChainId.SEPOLIA]: ChainId.BIZTESTNET,
  [ChainId.BIZTESTNET]: ChainId.SEPOLIA
};

export const DIRECTION_CHAIN: { [key: string]: number } = {
  [ChainId.HARDHAT]: BridgeDirection.ETHNET_BIZNET,
  [ChainId.STANDALONE]: BridgeDirection.BIZNET_ETHNET,
  [ChainId.MAINNET]: BridgeDirection.ETHNET_BIZNET,
  [ChainId.BIZNET]: BridgeDirection.BIZNET_ETHNET,
  [ChainId.SEPOLIA]: BridgeDirection.ETHNET_BIZNET,
  [ChainId.BIZTESTNET]: BridgeDirection.BIZNET_ETHNET
};

export const SWAPABLE_CHAIN: ChainId[] = [ChainId.STANDALONE, ChainId.BIZNET, ChainId.BIZTESTNET];

export const BOA_SYMBOL = "BOA";
export const TOKEN_TAGS = {
  TOKEN: "TOKEN",
  BRIDGE: "BRIDGE"
};
export const PAGES = {
  my: "my",
  swap: "swap",
  bridge: "bridge",
  pool: "pool"
};

// a list of tokens by chain
type ChainTokenList = {
  readonly [chainId in ChainId]: Token[];
};

export const DAI = new Token(
  ChainId.MAINNET,
  "0x6B175474E89094C44Da98b954EedeAC495271d0F",
  18,
  "DAI",
  "Dai Stablecoin"
);
export const USDC = new Token(ChainId.MAINNET, "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48", 6, "USDC", "USD//C");
export const USDT = new Token(ChainId.MAINNET, "0xdAC17F958D2ee523a2206206994597C13D831ec7", 6, "USDT", "Tether USD");
export const COMP = new Token(ChainId.MAINNET, "0xc00e94Cb662C3520282E6f5717214004A7f26888", 18, "COMP", "Compound");
export const MKR = new Token(ChainId.MAINNET, "0x9f8F72aA9304c8B593d555F12eF6589cC3A579A2", 18, "MKR", "Maker");
export const AMPL = new Token(ChainId.MAINNET, "0xD46bA6D942050d489DBd938a2C909A5d5039A161", 9, "AMPL", "Ampleforth");
export const WBTC = new Token(ChainId.MAINNET, "0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599", 18, "WBTC", "Wrapped BTC");
export const SUSHI = new Token(
  ChainId.MAINNET,
  "0x6B3595068778DD592e39A122f4f5a5cF09C90fE2",
  18,
  "SUSHI",
  "SushiToken"
);
export const YAM = new Token(ChainId.MAINNET, "0x0e2298E3B3390e3b945a5456fBf59eCc3f55DA16", 18, "YAM", "YAM");
export const RUNE = new Token(ChainId.MAINNET, "0x3155BA85D5F96b2d030a4966AF206230e46849cb", 18, "RUNE", "RUNE.ETH");
export const YFI = new Token(ChainId.MAINNET, "0x0bc529c00C6401aEF6D220BE8C6Ea1667F6Ad93e", 18, "YFI", "Yearn");
export const CREAM = new Token(ChainId.MAINNET, "0x2ba592F78dB6436527729929AAf6c908497cB200", 18, "CREAM", "Cream");
export const BAC = new Token(ChainId.MAINNET, "0x3449FC1Cd036255BA1EB19d65fF4BA2b8903A69a", 18, "BAC", "Basis Cash");
export const FXS = new Token(ChainId.MAINNET, "0x3432B6A60D23Ca0dFCa7761B7ab56459D9C964D0", 18, "FXS", "Frax Share");
export const CRV = new Token(
  ChainId.MAINNET,
  "0xD533a949740bb3306d119CC777fa900bA034cd52",
  18,
  "CRV",
  "Curve Dao Token"
);
export const ALPHA = new Token(
  ChainId.MAINNET,
  "0xa1faa113cbE53436Df28FF0aEe54275c13B40975",
  18,
  "ALPHA",
  "AlphaToken"
);

const WDEV_ONLY: ChainTokenList = {
  [ChainId.MAINNET]: [WDEV[ChainId.MAINNET]],
  [ChainId.HARDHAT]: [WDEV[ChainId.HARDHAT]],
  [ChainId.STANDALONE]: [WDEV[ChainId.STANDALONE]],
  [ChainId.SEPOLIA]: [WDEV[ChainId.SEPOLIA]],
  [ChainId.BIZTESTNET]: [WDEV[ChainId.BIZTESTNET]],
  [ChainId.BIZNET]: [WDEV[ChainId.BIZNET]]
};

// used to construct intermediary pairs for trading
export const BASES_TO_CHECK_TRADES_AGAINST: ChainTokenList = {
  ...WDEV_ONLY,
  [ChainId.MAINNET]: [
    ...WDEV_ONLY[ChainId.MAINNET],
    DAI,
    USDC,
    USDT,
    SUSHI,
    YAM,
    WBTC,
    RUNE,
    CREAM,
    BAC,
    FXS,
    CRV,
    ALPHA
  ]
};

/**
 * Some tokens can only be swapped via certain pairs, so we override the list of bases that are considered for these
 * tokens.
 */
export const CUSTOM_BASES: { [chainId in ChainId]?: { [tokenAddress: string]: Token[] } } = {
  [ChainId.MAINNET]: {
    [AMPL.address]: [DAI, WDEV[ChainId.MAINNET]]
  }
};

// used for display in the default list when adding liquidity
export const SUGGESTED_BASES: ChainTokenList = {
  ...WDEV_ONLY,
  [ChainId.MAINNET]: [...WDEV_ONLY[ChainId.MAINNET], DAI, USDC, USDT]
};

// used to construct the list of all pairs we consider by default in the frontend
export const BASES_TO_TRACK_LIQUIDITY_FOR: ChainTokenList = {
  ...WDEV_ONLY,
  [ChainId.MAINNET]: [...WDEV_ONLY[ChainId.MAINNET], DAI, USDC, USDT]
};

export const PINNED_PAIRS: { readonly [chainId in ChainId]?: [Token, Token][] } = {
  [ChainId.MAINNET]: [
    [
      new Token(ChainId.MAINNET, "0x5d3a536E4D6DbD6114cc1Ead35777bAB948E3643", 8, "cDAI", "Compound Dai"),
      new Token(ChainId.MAINNET, "0x39AA39c021dfbaE8faC545936693aC917d5E7563", 8, "cUSDC", "Compound USD Coin")
    ],
    [USDC, USDT],
    [DAI, USDT]
  ]
};

export interface WalletInfo {
  connector?: AbstractConnector;
  name: string;
  iconName: string;
  description: string;
  href: string | null;
  color: string;
  primary?: true;
  mobile?: true;
  mobileOnly?: true;
}

export const SUPPORTED_WALLETS: { [key: string]: WalletInfo } = {
  /* INJECTED: {
    connector: injected,
    name: 'Injected',
    iconName: 'arrow-right.svg',
    description: 'Injected web3 provider.',
    href: null,
    color: '#010101',
    primary: true
  },*/
  METAMASK: {
    connector: injected,
    name: "MetaMask",
    iconName: "metamask.png",
    description: "Easy-to-use browser extension.",
    href: null,
    color: "#E8831D"
  },
  METAMASK_MOBILE: {
    name: "MetaMask",
    iconName: "metamask.png",
    description: "Open in MetaMask app.",
    href: process.env.REACT_APP_DEEPLINK || "",
    color: "#E8831D",
    mobile: true,
    mobileOnly: true
  } /*
  ,
  WALLET_CONNECT: {
    connector: walletconnect,
    name: "WalletConnect",
    iconName: "walletConnectIcon.svg",
    description: "Connect to Trust Wallet, Rainbow Wallet and more...",
    href: null,
    color: "#4196FC"
  }

  LATTICE: {
    connector: lattice,
    name: 'Lattice',
    iconName: 'gridPlusWallet.png',
    description: 'Connect to GridPlus Wallet.',
    href: null,
    color: '#40a9ff',
    mobile: true
  },
  WALLET_LINK: {
    connector: walletlink,
    name: 'Coinbase Wallet',
    iconName: 'coinbaseWalletIcon.svg',
    description: 'Use Coinbase Wallet app on mobile device',
    href: null,
    color: '#315CF5'
  },
  COINBASE_LINK: {
    name: 'Open in Coinbase Wallet',
    iconName: 'coinbaseWalletIcon.svg',
    description: 'Open in Coinbase Wallet app.',
    href: 'https://go.cb-w.com/mtUDhEZPy1',
    color: '#315CF5',
    mobile: true,
    mobileOnly: true
  },
  FORTMATIC: {
    connector: fortmatic,
    name: 'Fortmatic',
    iconName: 'fortmaticIcon.png',
    description: 'Login using Fortmatic hosted wallet',
    href: null,
    color: '#6748FF',
    mobile: true
  },
  Portis: {
    connector: portis,
    name: 'Portis',
    iconName: 'portisIcon.png',
    description: 'Login using Portis hosted wallet',
    href: null,
    color: '#4A6C9B',
    mobile: true
  }*/
};

export const NetworkContextName = "NETWORK";

// default allowed slippage, in bips
export const INITIAL_ALLOWED_SLIPPAGE = 50;
// 20 minutes, denominated in seconds
export const DEFAULT_DEADLINE_FROM_NOW = 60 * 20;

// one basis point
export const ONE_BIPS = new Percent(JSBI.BigInt(1), JSBI.BigInt(10000));
export const BIPS_BASE = JSBI.BigInt(10000);
// used for warning states
export const ALLOWED_PRICE_IMPACT_LOW: Percent = new Percent(JSBI.BigInt(100), BIPS_BASE); // 1%
export const ALLOWED_PRICE_IMPACT_MEDIUM: Percent = new Percent(JSBI.BigInt(300), BIPS_BASE); // 3%
export const ALLOWED_PRICE_IMPACT_HIGH: Percent = new Percent(JSBI.BigInt(500), BIPS_BASE); // 5%
// if the price slippage exceeds this number, force the user to type 'confirm' to execute
export const PRICE_IMPACT_WITHOUT_FEE_CONFIRM_MIN: Percent = new Percent(JSBI.BigInt(1000), BIPS_BASE); // 10%
// for non expert mode disable swaps above this
export const BLOCKED_PRICE_IMPACT_NON_EXPERT: Percent = new Percent(JSBI.BigInt(1500), BIPS_BASE); // 15%

// used to ensure the user doesn't send so much DEV so they end up with <.01
export const MIN_ETH: JSBI = JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(16)); // .01 DEV
export const BETTER_TRADE_LINK_THRESHOLD = new Percent(JSBI.BigInt(75), JSBI.BigInt(10000));
