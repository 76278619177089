import React from "react";
import styled from "styled-components";

export const BoxBody = styled.main`
  position: relative;
`;

export default function BoxBd() {
  return (
    <>
      <BoxBody></BoxBody>
    </>
  );
}
